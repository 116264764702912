import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Flex from "components/layout/Flex";
import Logo from "components/pageElements/Logo";
import MenuIcon from "components/nav/MenuIcon";
import Menu from "components/nav/Menu";
import useAuth from "hooks/useAuth";

const StyledComponent = styled.div`
	position: fixed;
	display: flex;
	top: 0;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 1rem;

	button {
		border: none;
		background: none;
	}

	button:hover {
		background-color: initial;
	}

	@media (max-width: 768px) {
		padding: 1rem;
	}
`;

const Header = () => {
	const { isLoggedIn, user, logout } = useAuth();
	const [menuOpen, toggleMenu] = useState(false);

	const openMenu = () => {
		toggleMenu(true);
	};

	const closeMenu = () => {
		toggleMenu(false);
	};

	return (
		<StyledComponent>
			<Flex
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Link to="/" className="noStyle"><Logo /></Link>
				<button onClick={openMenu} type="button">
					<MenuIcon />
				</button>
			</Flex>

			{ menuOpen
				? (
					<Menu
						closeMenu={closeMenu}
						isLoggedIn={isLoggedIn}
						logout={logout}
						client={user?.client}
						isAdmin={user?.role === "admin"}
					/>
				)
				: null }
		</StyledComponent>
	);
};

export default Header;
