import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import CloseIcon from "components/buttons/CloseIcon";

const StyledComponent = styled.div`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: flex-end;
	flex-direction: column;
	z-index: 999;
	transition: 0.5s all ease;


	.close-button {
		position: absolute;
		top: 1.5rem;
		right: 1rem;
	}

	.menu-content {
		display: flex;
		flex-direction: column;
		padding: 6rem;
		background-color: var(--accent-color);
		box-shadow: 0 0 10px rgba(0,0,0,0.1);
		text-align: left;

		@media (max-width: 768px) {
		justify-content: center;
		width: 100%;
		height: 100%;
		}
	}

	a {
		color: white;
	}

	a:hover {
		color: black;
	}
	
	.active-link {
		color: black !important;
	}
`;

const Menu = ({
	closeMenu, isLoggedIn, logout, isAdmin
}) => (
	<StyledComponent
		onClick={closeMenu}
		onKeyDown={closeMenu}
	>
		<div className="menu-content">
			<button onClick={closeMenu} className="close-button" type="button">
				<CloseIcon />
			</button>
			<h2><Link to="/" activeClassName="active-link">Home</Link></h2>
			<h2><Link to="/work" activeClassName="active-link">Work</Link></h2>
			<h2><Link to="/services" activeClassName="active-link">Services</Link></h2>
			<h2><Link to="/contact" activeClassName="active-link">Contact</Link></h2>
			<hr />
			{
				isLoggedIn
					? (
						<>
							<h2><Link to="/client" activeClassName="active-link">My Area</Link></h2>
							{ isAdmin
								? (
									<>
										<br />
										<h2 style={{ color: "white", opacity: 0.4 }}>Admin</h2>
									</>
								) : null }
							{ isAdmin ? <h2><Link to="/admin/listUsers" activeClassName="active-link">Users</Link></h2> : null }
							{ isAdmin ? <h2><Link to="/admin/listProjects" activeClassName="active-link">Projects</Link></h2> : null }
							{ isAdmin ? <h2><Link to="/admin/createEditUser" activeClassName="active-link">New User</Link></h2> : null }
							{ isAdmin ? <h2><Link to="/admin/createEditProject" activeClassName="active-link">New Project</Link></h2> : null }
							<br />
							<h2>
								<Link
									to="/"
									onClick={() => logout().then(() => {
										navigate("/");
									})}
									activeClassName="active-link"
								>
									Sign Out
								</Link>
							</h2>
						</>
					)
					: <h2><Link to="/login" activeClassName="active-link">Client Area</Link></h2>
			}

		</div>
	</StyledComponent>
);

export default Menu;
