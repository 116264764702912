import React from "react";
import styled from "styled-components";

const StyledComponent = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    flex-direction: ${(props) => props.flexDirection || "column"};
    flex: ${(props) => props.flex || "0 1 auto"};
    flex-grow: ${(props) => props.flexGrow};
    flex-basis: ${(props) => props.flexBasis};
    flex-shrink: ${(props) => props.flexShrink};
    flex-wrap: ${(props) => props.flexWrap || "nowrap"};
    align-items: ${(props) => props.alignItems || "stretch"};
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    width: ${(props) => props.width || "auto"};
    height: ${(props) => props.height || "initial"};
    max-width: ${(props) => props.maxWidth};
    min-height: ${(props) => props.minHeight};
    border: ${(props) => props.border};
    border-top: ${(props) => props.borderTop};
    border-bottom: ${(props) => props.borderBottom};
    border-left: ${(props) => props.borderLeft};
    border-right: ${(props) => props.borderRight};
    padding: ${(props) => props.padding};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
`;

const Flex = ({ children, ...rest }) => (
	<StyledComponent {...rest}>
		{children}
	</StyledComponent>
);

export default Flex;
