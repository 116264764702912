import React from "react";
import styled from "styled-components";

const StyledComponent = styled.div`
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	width: 100%;

	.column {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-right: 6rem;
		flex:1;
		@media (max-width: 768px) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.column:last-of-type {
		padding-right: 0rem;
	}


	${(props) => !props.dontCollapse && `
     @media only screen and (max-width: 768px) {
		flex-direction: column;
		.column {
			padding-right: 0rem;
		}
     }
  `}
	

`;

const Columns = ({ children, ...rest }) => (
	<StyledComponent {...rest}>
		{children}
	</StyledComponent>
);

export default Columns;
